import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteTemplateService {


    paginate(slug, index = null) {
        let url = API_URL + 'user/market-place/product/';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }
    get(id){
        let url = API_URL + 'user/market-place/product/'+id;
        return apiService.get(url);
    }

    getPageByTemplate(name) {
        let url = API_URL + 'user/template-pages/'+name +'?type=default';
        return apiService.get(url);
    }

    importPage(websiteName,templateName,data){
        let url = API_URL + 'user/website/'+websiteName+'/store/pages/'+templateName;
        return apiService.post(url, data);
    }

    create(slug, data) {
        let url = API_URL + 'user/market-place/product/';
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/market-place/product/' + id+'/update'
        return apiService.post(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/market-place/product/' + id
        return apiService.delete(url);
    }
    deleteAll(slug){
        let url = API_URL + 'user/website/'+slug+'/page/delete/all'
        return apiService.get(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/market-place/product/' + id
        return apiService.get(url);
    }


}
