<template>
  <div>
    <h3>Templates</h3>
    <v-btn
      text
      :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
      outlined
      class="btn btn-lg btn-secondary-main"
      style="background:transparent;"
    >
      <i class="fas fa-arrow-left"></i> Back
    </v-btn>
    <v-app>
      <v-row>
        <v-col class="d-flex" cols="6" sm="6" lg="4" v-for="(template,i) in templates" :key="i">
          <v-card outlined class="mx-auto p-2">

            <progressive-img :src="template.thumbnail_image" contain></progressive-img>
            <h3 class="ml-3 mt-5 mb-5">{{template.title}}</h3>
            <v-card-action class="d-flex">
              <!-- <router-link
                class="btn btn-success text-white"
                :to="{ name: 'confirm-templates', params: { domainname:  siteUrl, templatename : template.id } }"
              >Activate</router-link> -->

              <a href="#" @click.prevent="openDialog(template.id)"  class="btn btn-sm btn-warning text-white ml-5">Import</a>
               <a href="#" @click.prevent="openPreview(template.folder)" class="btn btn-sm btn-info text-white ml-5">Preview</a>

            </v-card-action>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog scrollable scrollable v-model="dialog" persistent max-width="800">

      <v-card>
        <v-card-title class="headline">Import Template</v-card-title>
        <v-card-text>

            <v-card-text>
              <v-divider></v-divider>
              <v-list one-line>
                <v-list-item-group active-class="green--text">
                   <v-list-item three-line @click="option3">
                    <v-list-item-content>
                      <v-list-item-title>Clean & Fresh Import New Template </v-list-item-title>
                      <v-list-item-subtitle>This option will import all sample pages from the new theme including new header, footer and color schemes.
                        Your existing pages may not be accurate in design but you can edit the new imported pages and start editing with your existing content
                        </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item one-line @click="option2">
                    <v-list-item-content>
                      <v-list-item-title>Preserve Old data & Import New Template </v-list-item-title>
                      <v-list-item-subtitle>This option will  import all sample pages from the new theme.
                        The resulting website may not look as the original theme demo and will need additional work to organise website pages.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                </v-list-item-group>
              </v-list>
              <v-divider></v-divider>
            </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </v-app>
  </div>
</template>
<script>
import Websitetemplate from "@/services/Websites/WebsiteTemplateService";
const WebsiteTemplateService = new Websitetemplate();
import WebsiteMarketplaceProduct from "@/services/Websites/WebsiteMarketPlaceProductService";
const WebsiteMarketPlaceProductService = new WebsiteMarketplaceProduct();
import Website from "@/services/Websites/WebsiteService";
const WebsiteService = new Website();
export default {
  name: "website-template",
  data() {
    return {
      preview_url:process.env.VUE_APP_PREVIEW_URL,
      templates: [],
      template: null,
      selectedTemplateId :null,
      dialog:false,
      website: {
        id: null,
        title: null,
        user_id: null,
        template_id: null,
        is_custom_domain: null,
        plan_id: null,
        plan_expiry_date: null,
        lifetime_membership: null,
        is_linked_organisation: null,
        is_active: null,
        linked_organisation_id: null
      },
      pages: [""],
      importPage: {
        pages: null
      }
    };
  },
  methods: {
    getMarketPlaceTemplate() {
      WebsiteMarketPlaceProductService.paginate()
        .then(res => {
          this.templates = res.data.data;
        })
        .catch(err => {});
    },
    initWebsite() {
      WebsiteService.show(this.siteUrl).then(res => {
        this.website = res.data.website;
      });
    },
    getTemplate(id) {
      WebsiteTemplateService.get(id).then(res => {
        this.template = res.data.product;
      });
    },
    import() {
      this.importPage.pages = this.pages;
      WebsiteTemplateService.importPage(
        this.siteUrl,
        this.website.template_id,
        this.importPage
      )
        .then(res => {
          this.closeDialog();
           this.$router.push({
                      name: "manage-pages",
                      params: { domainname: this.siteUrl }
                    });
          this.$snotify.success("Template  Updated");
        })
        .catch(err => {});
    },
    openPreview(folder){
     window.open(this.preview_url+'?theme='+folder);
    },
    openDialog(id){
      this.selectedTemplateId = id;
      this.dialog = true;
    },
     closeDialog(){
      this.dialog = false;
    },
    option2() {
        this.$confirm(
            {
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        this.website.template_id = this.selectedTemplateId;
                        WebsiteService.update(this.siteUrl, this.website).then(res => {
                            // WebsiteTemplateService.deleteAll(this.siteUrl)
                            //   .then(res => {
                            WebsiteTemplateService.getPageByTemplate(this.selectedTemplateId)
                                .then(res => {
                                    this.pages = res.data;
                                    this.import();

                                    // this.settings.setup_steps = this.setup_steps;
                                })
                                .catch(err => {});
                            // })
                            // .catch(err => {});
                        });
                    }
                }
            }
        )

    },
    option3(){
        this.$confirm(
            {
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        this.website.template_id = this.selectedTemplateId;
                        WebsiteService.update(this.siteUrl, this.website).then(res => {
                            WebsiteTemplateService.deleteAll(this.siteUrl)
                                .then(res => {
                                    WebsiteTemplateService.getPageByTemplate(this.selectedTemplateId)
                                        .then(res => {
                                            this.pages = res.data;
                                            this.import();
                                        })
                                        .catch(err => {});
                                })
                                .catch(err => {});
                        });
                    }
                }
            }
        )

    }
  },
  components: {},
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: "Web Dashboard", route: "/websites" }
    // ]);
    this.getMarketPlaceTemplate();
    this.initWebsite();
  },
  computed: {
    siteUrl() {
      return this.$route.params.domainname;
    }
  }
};
</script>
